<template>
	<div class="forum">
		<tabBar :cardIndex="1" :activeIndexsa='1'></tabBar>
		<!-- <div style="height: 85px">
		  <div class="headers">
		    <div class="headss">
					<div v-for="(item, index) in list" :key="index" class="tips" :class="filg == index ? 'active_tip' : ''"
					  @click="qies(index)">
					  <img v-if="filg==index" :src='item.imgs' />
					  <img v-else :src='item.img' />
						<div>{{ item.name }}</div>
					</div>
				</div>
		  </div>
		</div> -->
		<div class="content">
			<div class="contents">
				<div class="cont_left">
					<div class="contforum" v-for="(item,index) in contlist" :key='index'>
						<div class="conthed">
							<img :src='item.headImg' v-if="item.headImg" />
							<img src='@/assets/img/user.png' v-else />
							<span>{{item.nickName}}</span>
						</div>
						<div @click="seedet(item.id)">
							<div class="contentxx" v-if="item.content">{{item.content}}</div>
							<div v-if="item.contentImgs" style="margin-top: 15px;">
								<div class="cont_img" v-if="item.contentImgs.length>1">
									<!-- <img :src="ites" v-for="(ites,ind) in item.contentImgs" :key='ind' /> -->
									<div :style="{backgroundImage:'url('+ites+')'}" v-for="(ites,ind) in item.contentImgs" :key='ind'></div>
								</div>
								<div class="cont_imgs" v-else>
									<img :src="ites" v-for="(ites,ind) in item.contentImgs" :key='ind' />
								</div>
							</div>
							<div v-if="item.remarks" style="margin-bottom: 30px;margin-top: 20px;">
								<video :src='item.remarks' style="width: 400px;" controls></video>
							</div>
						</div>
						<div class="cont_boot">
							<div>{{item.createTime}}</div>
							<div class="cont_boots">
								<div style="margin-right: 37px;cursor: pointer;" @click="report(item)">举报</div>
								<div style="margin-right: 31px;">{{item.viewNum}}浏览</div>
								<div class="cont_pinl" style="margin-right: 29px;" @click="comments(item,index)">
									<img style="height: 18px;width: 19px;" src="../../assets/img/xie/pinglun.png" />
									<span>{{item.replyNum}}</span>
								</div>
								<div class="cont_pinl" @click="comment(item.id,index,!item.isDoUp)">
									<img style="height: 18px;width: 18px;" v-if="!item.isDoUp" src="../../assets/img/xie/zan.png" />
									<img style="height: 18px;width: 18px;" v-else src="../../assets/img/4509.png" />
									<span :style="item.isDoUp?'color:#f26034':'' ">{{item.giveNum}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="noalls" v-if="istotals">没有更多了</div>
				</div>
				<div :class="contlist.length<1?'actv_contrig':'cont_rigth'">
					<div>发布论坛</div>
					<div class="fabu" @click="rels">
						<img src="../../assets/img/xie/fabu.png" />
						<span>发布动态</span>
					</div>
				</div>
			</div>
			<div class="heads" v-if="topesa" @click="tops">
				<i class="el-icon-arrow-up" style="font-size: 30px;color: #ACACAC;"></i>
			</div>
		</div>
		<div class="dilogs">
			<el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
			  <div v-if="release==1">
					<div class="dilog_a">
						<div class="dils_labe">举报原因：</div>
						<el-select v-model="value" placeholder="请选择" style="width: 400px;" @change="selects">
							<el-option value="内容涉及色情暴力"></el-option>
							<el-option value="内容涉及欺诈造假"></el-option>
							<el-option value="内容涉及涉及违禁商品或服务"></el-option>
							<el-option value="感觉被冒犯或被歧视"></el-option>
							<el-option value="涉及侵权问题"></el-option>
							<el-option value="内容宣扬仇恨"></el-option>
							<el-option value="内容涉及垃圾营销"></el-option>
							<el-option value="人身攻击"></el-option>
							<el-option value="其他"></el-option>
						</el-select>
					</div>
					<div class="dilog_a" v-if="inputs">
						<div class="dils_labe">其他原因：</div>
						<el-input v-model="input" placeholder="请输入内容" type='textarea' :maxlength='500' :rows="4" show-word-limit style="width: 400px;"></el-input>
					</div>
				</div>
				<div v-else-if="release==2">
					<el-input v-model="comcont.content" type='textarea' maxlength="500" :rows='5' show-word-limit placeholder="请输入内容"></el-input>
				</div>
				<div v-else class="dilog_rel">
					<el-input v-model="forumdata.content" placeholder="聊聊此刻的想法吧~" type='textarea' :maxlength='500' :rows="4" show-word-limit></el-input>
					<div style="display: flex;margin-top: 11px;">
						<div v-for="(item,index) in format" :key='index' class="formats" @click="forms(index)">
							<img :src='item.img1' v-if="index===filgform" />
							<img :src="item.img" v-else />
						</div>
					</div>
					<div v-if="filgform===0" style="margin-top: 10px;">
						<el-upload
						  :action="UploadUrl"
						  list-type="picture-card"
							:limit="9"
							:on-exceed="() => { $message.error('超过最大可上传数！');}"
						  :on-remove="handleRemove"
							:on-success="success"
							:file-list="fileList"
							:before-upload="beforeUpload"
							:headers="headersa">
						  <i class="el-icon-plus"></i>
						</el-upload>
					</div>
					<div v-if="filgform===1" style="margin-top: 10px;">
						<el-upload
						  class="upload-demo"
						  :action="UploadUrl"
						  multiple
						  :limit="1"
						  :on-exceed="() => { $message.error('超过最大可上传数！');}"
						  :on-remove="handleRemovea"
						  :on-success="successa"
							:file-list="fileLists"
						  :before-upload="beforeUploada"
						  :headers="headersa">
						  <el-button size="small" type="primary">点击上传</el-button>
						  <div slot="tip" class="el-upload__tip">建议上传mp4视频文件</div>
						</el-upload>
					</div>
				</div>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="dialogVisible = false">取 消</el-button>
			    <el-button v-if="release==1" type="primary" @click="reporda">确 定</el-button>
					<el-button v-else-if="release==2" type="primary" @click="commentsa">确 定</el-button>
					<el-button v-else type="primary" @click="releases">确 定</el-button>
			  </span>
			</el-dialog>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import tabBar from "@/components/tabBar.vue";
	import footers from "@/components/footer.vue";
	import { getforumLsit,putaddUp,postforumReply } from "@/request/homeApi";
	import { addforum,oupdet,reportforum } from "@/request/xie";
	export default{
		components:{
			tabBar,
			footers
		},
		data(){
			return{
				filg:1,
				list: [
				  { name: "博客",img:require('../../assets/img/xie/boke.png'),imgs:require('../../assets/img/xie/boke2.png')},
				  { name: "论坛",img:require('../../assets/img/xie/luntan2.png'),imgs:require('../../assets/img/xie/luntan.png')},
				],
				parsm:{
					pageNumber:1,
					pageSize:5,
					type:1,
					authFlag:'PASS'
				},
				contlist:[],
				dialogVisible:false,
				title:'',
				value:'',
				inputs:false,
				input:'',
				repordata:{
					reasonName:'',
					forumId:''
				},
				release:1,
				format:[
					{img:require('../../assets/img/xie/pic.png'),img1:require('../../assets/img/xie/pic1.png')},
					{img:require('../../assets/img/xie/shipin.png'),img1:require('../../assets/img/xie/shipin1.png')}
				],
				filgform:0,
				UploadUrl:'',
				headersa:{},
				tokens:'',
				fileList:[],
				fileLists:[],
				forumdata:{
					content:'',
					contentImg:'',
					authFlag:'TOBEAUDITED',
					remarks:'',
					type:1
				},
				comcont:{
					forumId: '',
					content: '',
				},
				textindex:null,
				total:'',
				istotals:false,
				topesa:false
			}
		},
		mounted() {
			this.UploadUrl=oupdet()
			if(localStorage.getItem('token')){
				this.tokens=localStorage.getItem('token')
				this.headersa={
					accessToken:this.tokens
				}
			}
			this.getlist()
			addEventListener('scroll', this.handleScroll)//监听函数
		},
		beforeDestroy() {
			window.removeEventListener("scroll",this.handleScroll)
		},
		methods:{
			handleScroll(){
				//变量scrollTop是滚动条滚动时，距离顶部的距离
			 let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
			 if(scrollTop>1000){
				 this.topesa=true
			 }else{
				 this.topesa=false
			 }
			 //变量windowHeight是可视区的高度
			 let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
			 //变量scrollHeight是滚动条的总高度
			 let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
				//滚动条到底部的条件
				if(scrollTop+windowHeight == scrollHeight){
				 //你想做的事情
				 if(this.parsm.pageNumber<=this.total){
					 this.parsm.pageNumber=this.parsm.pageNumber+1
					 this.getlist()
				 }
				}
			},
			//获取论坛列表
			async getlist(){
				let res=await getforumLsit(this.parsm)
				this.total=res.result.list.pages
				res.result.list.records.forEach((item,index)=>{
					this.contlist.push(item)
				})
				if (this.contlist.length == res.result.list.total) {
				  this.istotals = true;
				}
				this.contlist.forEach((item,index)=>{
					if(item.contentImg){
						let sk=item.contentImg.split(',')
						item.contentImgs=sk
					}
				})
				//console.log(this.contlist)
			},
			//切换
			qies(ind) {
			  if(ind == 0){
			    this.$router.push({path:"compare"})
			  }
			  this.$nextTick(() => {
			    this.filg = ind;
			  });
				
			},
			//打开举报弹框
			report(item){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				this.title='举报'
				this.release=1
				this.value=''
				this.input=''
				this.repordata.forumId=item.id
				this.inputs=false
				this.dialogVisible=true
			},
			//打开评论弹框
			comments(item,ind){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				this.title='评论'
				this.release=2
				this.comcont.content=''
				this.comcont.forumId=item.id
				this.textindex=ind
				this.dialogVisible=true
			},
			//打开发布论坛弹框
			rels(){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				this.fileList=[]
				this.fileLists=[]
				this.forumdata={
					content:'',
					contentImg:'',
					remarks:'',
					authFlag:'TOBEAUDITED',
					type:1
				}
				this.title='发布动态'
				this.release=3
				this.dialogVisible=true
			},
			//选择图片、视频
			forms(ind){
				this.filgform=ind
			},
			//删除图片
			handleRemove(fileList){
				let indexs=''
				this.fileList.forEach((item,index)=>{
					if(item.uid==fileList.uid){
						indexs=index
					}
				})
				this.fileList.splice(indexs,1)
			},
			//上传图片成功之后
			success(res,file){
				if (res.result) {
					this.fileList.push({name:file.name,url:res.result})
				}
			},
			//上传图片之前
			beforeUpload(file,fileList){
				const isJPG =
						file.type === 'image/jpg' ||
						file.type === 'image/JPG' ||
						file.type === 'image/png' ||
						file.type === 'image/PNG' ||
						file.type === 'image/jpeg' ||
						file.type === 'image/JPEG'
				const isLt10M = file.size / 1024 / 1024 < 10
				if (!isJPG) {
					this.$message.error('上传图片只能为jpg或png或jpeg格式')
					return false
				}
				if (!isLt10M) {
					this.$message.error('上传的图片大小不能超过10MB!')
					return false
				}
			},
			//删除视频
			handleRemovea(){
				this.fileLists = []
				this.forumdata.remarks=''
			},
			//上传视频成功之后
			successa(res,file){
				if (res.result) {
					this.fileLists.push({name:file.name,url:res.result})
					this.forumdata.remarks=res.result
				}
			},
			//上传视频之前
			beforeUploada(file){
				if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
						this.$message.error("请上传正确的视频格式")
						return false;
				}
			},
			//查看详情
			seedet(ids){
				this.$router.push({
					path:'forumdet',
					query:{id:ids}
				})
			},
			//发布论坛
			releases(){
				if(this.filgform==0&&this.forumdata.content==''){
					this.$message.error('论坛内容不能为空')
					return false
				}
				if(this.filgform==1&&this.fileLists.length<1){
					this.$message.error('论坛视频不能为空')
					return false
				}
				if(this.filgform==0){
					let sk=[]
					this.fileList.forEach((item,index)=>{
						sk.push(item.url)
					})
					this.forumdata.contentImg=sk.join(',')
					this.forumdata.remarks=''
				}else{
					this.forumdata.contentImg=''
				}
				addforum(this.forumdata).then((res)=>{
					this.dialogVisible=false
					this.$message.success('论坛发布成功，请等待审核，审核结果将通过消息通知到您。')
					this.parsm.pageNumber=1
					this.getlist()
				})
			},
			//举报原因
			selects(val){
				if(val=='其他'){
					this.inputs=true
				}else{
					this.inputs=false
				}
			},
			//举报
			reporda(){
				if(!this.inputs&&this.value==''){
					this.$message.error('请选择举报原因')
					return false
				}
				if(this.inputs&&this.input==''){
					this.$message.error('请填写举报原因')
					return false
				}
				if(this.inputs){
					this.repordata.reasonName=this.input
				}else{
					this.repordata.reasonName=this.value
				}
				reportforum(this.repordata).then((res)=>{
					if(res.code==200){
						this.$message.success('举报成功')
					}
					this.dialogVisible=false
				})
			},
			//点赞
			comment(id,index,isfor){
				if(!this.tokens){
					this.$message.error('请登录之后再操作')
					return false
				}
				putaddUp(id,isfor).then((res)=>{
					if (res.code == 200) {
					  if(isfor){
					    this.contlist[index].isDoUp = true;
					    this.contlist[index].giveNum = Number(this.contlist[index].giveNum) + 1;
					  }else{
					    this.contlist[index].isDoUp = false;
					    this.contlist[index].giveNum = Number(this.contlist[index].giveNum) - 1;
					  }
					  this.$message.success("操作成功！");
					}
				})
			},
			//回到顶部
			tops(){
				document.documentElement.scrollTop=0
			},
			//评论
			commentsa(){
				if(this.comcont.content==''){
					this.$message.error('请填写评论内容')
					return false
				}
				postforumReply(this.comcont).then((res)=>{
					if (res.code == 200) {
					  this.dialogVisible=false
					  this.contlist[this.textindex].replyNum = Number(this.contlist[this.textindex].replyNum) + 1;
					  this.$forceUpdate()
					  this.$message.success("评论成功！");
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.forum{
		.headers {
		  width: 100%;
		  height: 85px;
		  background-color: #ededed;
		  color: #535353;
		  font-size: 16px;
		  display: flex;
		  flex-flow: row;
		  justify-content: center;
		  align-items: center;
		  position: fixed;
		  z-index: 99;
		  left: 0;
		  top: 80px;
			.headss{
				margin-left: 80px;
				height: 100%;
				display: flex;
				flex-flow: row;
				justify-content: center;
				align-items: center;
			}
		  .tips {
		    margin-left: 95px;
		    cursor: pointer;
				display: flex;
				flex-flow: column;
				justify-content: center;
				img{
					width: 33px;
					height: 31px;
				}
		  }
		  .active_tip {
		    color: #00a3e0;
		  }
		}
		.content{
			display: flex;
			flex-flow: row;
			justify-content: center;
			background-color: #F7F7F7;
			padding-top: 19px;
			padding-bottom: 39px;
			box-sizing: border-box;
			padding-top: 120px;
			.contents{
				display: flex;
				width: 1005px;
			}
			.heads{
				width: 46px;
				height: 46px;
				background-color: #fff;
				border-radius: 50%;
				position: fixed;
				border: 1px solid #E2E2E2;
				bottom: 48px;
				cursor: pointer;
				right: 457px;
				text-align: center;
				line-height: 50px;
			}
			.cont_left{
				width: 670px;
				background-color: #fff;
				margin-right: 12px;
				padding: 0 22px 20px 22px;
				box-sizing: border-box;
				.contforum{
					margin-top: 26px;
					padding-bottom: 33px;
					border-bottom: 1px solid #E1E1E1;
					.conthed{
						display: flex;
						align-items: center;
						font-size: 16px;
						color: #535353;
						img{
							width: 40px;
							height: 40px;
							border-radius: 50%;
							margin-right: 11px;
						}
					}
					.contentxx{
						color: #535353;
						font-size: 18px;
						margin-top: 26px;
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						display:-webkit-box; 
						-webkit-box-orient:vertical;
						-webkit-line-clamp:2;
						cursor: pointer;
					}
					.cont_img{
						width: 100%;
						display: flex;
						flex-flow: row wrap;
						justify-content: space-between;
						margin-bottom: 17px;
						div{
							width: 200px;
							height: 200px;
							background-size: cover;
							margin-bottom: 13px;
							cursor: pointer;
						}
					}
					.cont_img:after{
						content: '';
						width: 33.33%;
					}
					.cont_imgs{
						margin-top: 5px;
						img{
							width: 290px;
							cursor: pointer;
						}
					}
					.cont_boot{
						display: flex;
						flex-flow: row;
						justify-content: space-between;
						align-items: center;
						color: #999999;
						margin-top: 30px;
						font-size: 14px;
					}
					.cont_boots{
						display: flex;
						font-size: 16px;
						align-items: center;
						.cont_pinl{
							cursor: pointer;
							display: flex;
							align-items: center;
							img{
								margin-right: 6px;
							}
						}
					}
				}
				.noalls {
				  text-align: center;
				  padding-top: 20px;
				  font-size: 16px;
				  color: #999999;
				}
			}
			.cont_rigth{
				width: 323px;
				height: 200px;
				background-color: #fff;
				position: fixed;
				right: 448px;
				padding: 20px;
				box-sizing: border-box;
				color: #535353;
				font-size: 18px;
				.fabu{
					margin-top: 30px;
					width: 100%;
					display: flex;
					flex-flow: column;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					img{
						width: 43px;
						height: 44px;
						margin-bottom:12px;
					}
					span{
						color: #999999;
						font-size: 16px;
					}
				}
			}
			.actv_contrig{
				width: 323px;
				height: 200px;
				background-color: #fff;
				right: 448px;
				padding: 20px;
				box-sizing: border-box;
				color: #535353;
				font-size: 18px;
				.fabu{
					margin-top: 30px;
					width: 100%;
					display: flex;
					flex-flow: column;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					img{
						width: 43px;
						height: 44px;
						margin-bottom:12px;
					}
					span{
						color: #999999;
						font-size: 16px;
					}
				}
			}
		}
		::v-deep .dilogs{
			.el-dialog__header{
				padding: 0 0 0 20px;
				height: 50px;
				line-height: 50px;
				box-sizing: border-box;
				border-radius: 4px 4px 0px 0px;
				font-size: 16px;
				color: #535353;
				background-color: #F8F9FB;
				button{
					display: none;
				}
			}
			.el-dialog__body{
				padding: 22px 25px 22px 27px;
				box-sizing: border-box;
			}
			.dialog-footer{
				display: inline-block;
				width: 100%;
				display: flex;
				flex-flow: row;
				justify-content: center;
				button:first-child{
					background-color: #97999B;
					color: #fff;
				}
			}
			.dilog_a{
				display: flex;
				margin-bottom: 25px;
				.dils_labe{
					width: 100px;
					text-align: right;
				}
			}
			.dilog_rel{
				.el-textarea__inner{
					background-color: #F8F9FB;
					border-color: #F8F9FB;
				}
				.formats{
					width: 40px;
					height: 40px;
					display: flex;
					flex-flow: row;
					justify-content: center;
					align-items: center;
					margin-right: 14px;
					border-radius: 50%;
					img{
						cursor: pointer;
						height: 24px;
					}
				}
				.activeform{
					background-color: #95999C;
				}
			}
		}
	}
</style>
